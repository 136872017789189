export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "necoCoinTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO token is the mother token of NECO FUN, and will establish connections to all sub-tokens"])},
        "maxSupply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max supply"])},
        "turnover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circulating Supply"])},
        "contractAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Address"])},
        "pool1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stake NECO to earn NECO"])},
        "pool2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stake BNB&NECO to earn NECO"])},
        "stakeAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Staked"])},
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About NECO FUN"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO FUN is committed to be next Nintendo, the steam platform in the blockchain world. Our aim is to let every player release vitality!"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neco Fun focuses on blockchain games and brings multiple Play-to-Earn ways. We will bring players a variety of P2E games as well as standalone games. Each standalone game is an NFT. Players purchase games through the blockchain and they permanently own those games (NFTs). What's more, they could play the game to earn more tokens. When they beat the game, they can also sell it as a NFT for extra income."])},
        "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
        "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go"])},
        "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange"])},
        "airdrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airdrop"])}
      },
      "zh": {
        "necoCoinTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO代币为NECO FUN的平台母币，将会关联后续所有游戏子币"])},
        "maxSupply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大供应量"])},
        "turnover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前流通量"])},
        "contractAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合约地址"])},
        "pool1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["质押NECO获取更多NECO"])},
        "pool2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["质押NECO&BNB获取更多NECO"])},
        "stakeAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["质押总量"])},
        "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于NECO FUN"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO FUN 致力于打造一个区块链版的任天堂，steam平台。我们的宗旨是：让每个玩家释放精彩！"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neco Fun 专注于区块链游戏，带来多样的Play-to-Earn方式。我们将会带给玩家多款P2E游戏，同样也会开发区块链的单机游戏。单机游戏中的每一款游戏是一个NFT，玩家通过区块链购买游戏，玩家将不仅获得这款游戏（NFT）的永久所有权，还可以通过游玩游戏获取更多代币，游戏通关以后还可以将游戏以NFT的形式卖出，获取更多的额外收益。"])},
        "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买"])},
        "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前往"])},
        "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["置换"])},
        "airdrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空投"])}
      }
    }
  })
}
